import React, {FunctionComponent} from "react"
import {CollectionType} from "./collection.type"
import tableStyles from "../../tech/table/table.module.css"
import {formatDate} from "../../tech/format/format.util"

type CollectionsTableProps = {
    collections: CollectionType[]
}

const CollectionsTable: FunctionComponent<CollectionsTableProps> = ({ collections }) => {
    if (collections.length === 0) {
        return <>No collections available yet.</>
    }

    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.table}>
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Credited At</th>
                    <th>Amount origin</th>
                    <th>Amount actual</th>
                    <th>Fee</th>
                    <th>Sender</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {collections.map((collection, i) => (
                    <tr key={i}>
                        <td>
                            {collection.id}
                        </td>
                        <td>
                            {formatDate(collection.creditedAt)}
                        </td>
                        <td>
                            {collection.amountOriginFormatted}
                        </td>
                        <td>
                            {collection.amountActualFormatted}
                        </td>
                        <td>
                            {collection.feeFormatted}
                        </td>
                        <td>
                            {collection.sender.name}
                            {collection.sender.address && (
                                <>
                                    <br/>
                                    {collection.sender.address}
                                    {collection.sender.country && <>,{" "}</>}
                                </>
                            )}
                            {collection.sender.country}
                            {collection.sender.routingCode && (
                                <>
                                    <br/>
                                    {collection.sender.routingCode}
                                </>
                            )}
                        </td>
                        <td>
                            {collection.status}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default CollectionsTable