import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {INVESTOR} from "../../paths"
import {InvestorWithDetailsType} from "../investor/investor.type"
import styles from "./dashboard.module.css"

const DashboardPoliticallyExposedPersonsNotCleared: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investors, setInvestors] = useState<InvestorWithDetailsType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setInvestors(await fetchClient.investorApi.getAllPepNotCleared())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={investors.length > 0 ? styles.boxRed : styles.box}>
            <div>
                <h3>Politically exposed but not yet clarified</h3>
                {state === "LOADED" && (
                    <>
                        {investors.length > 0 ? (
                            <>
                                The investors stated that they are politically exposed persons (PEP) but their status has not yet been clarified:
                                <ul>
                                    {investors.map(i => (
                                        <li key={i.investor.id}>
                                            <Link to={INVESTOR(i.investor.id)}>
                                                {i.investor.person?.firstName} {i.investor.person?.lastName}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>No affected investors found.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardPoliticallyExposedPersonsNotCleared