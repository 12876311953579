import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import ManualReconciliationLogForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {normalizeValues} from "../../../tech/form/form.util"
import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import {ManualReconciliationBankAccountType} from "../account/manual-reconciliation-bank-account.type"

const ManualReconciliationLogEdit = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "SUCCESS">("LOADING")
    const [log, setLog] = useState<ManualReconciliationLogType>()
    const [manualReconciliationBankAccounts, setManualReconciliationBankAccounts] = useState<ManualReconciliationBankAccountType[]>()

    const person = log?.investment.investor.person

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedLog,
                    fetchedManualReconciliationBankAccounts
                ] = await Promise.all([
                    fetchClient.reconciliationApi.getManualReconciliationLog(id!),
                    fetchClient.reconciliationApi.getAllManualReconciliationBankAccounts()
                ])
                setLog(fetchedLog)
                setManualReconciliationBankAccounts(fetchedManualReconciliationBankAccounts)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<ManualReconciliationLogType> = async (log) => {
        setState("LOADING")
        try {
            setLog(await fetchClient.reconciliationApi.updateManualReconciliationLog(id!, normalizeValues(log)))
            window.scrollTo({ top: 0 })
            setState("SUCCESS")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {state === "SUCCESS" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="Saved successfully."
                />
            )}
            {(state === "LOADED" || state === "ERROR" || state === "SUCCESS") && log && manualReconciliationBankAccounts && (
                <Section>
                    <SectionHeading title={`Edit Manual Reconciliation Log: ${log.investment.deal.name.completeOnboarding} - ${person?.firstName} ${person?.lastName} (${log.id})`}/>
                    <ManualReconciliationLogForm
                        log={log}
                        manualReconciliationBankAccounts={manualReconciliationBankAccounts}
                        onSubmit={onSubmit}
                        submitText="Save Manual Reconciliation Log"
                    />
                </Section>
            )}
        </>
    )
}

export default ManualReconciliationLogEdit