import React, {ChangeEvent, FunctionComponent, useContext, useState} from "react"
import LoadingBar from "../../../../tech/loading/bar/bar.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import Button from "../../../../tech/button/button.component"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import {MimeTypeOpenApi} from "../../../../generated"
import {FileUploadSelectFormValueType} from "../upload-select.type"
import styles from "./upload.module.sass"
import {preventSubmissionOnEnter} from "../../../../tech/form/form.util"

type FileUploadProps = {
    accept: MimeTypeOpenApi[]
    close: () => void
    setFormValue: (value: FileUploadSelectFormValueType) => void
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
    accept,
    close,
    setFormValue
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [file, setFile] = useState<File>()
    const [identifier, setIdentifier] = useState<string>()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR" | "SUCCESS">("NONE")

    function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || undefined
        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    function handleIdentifierChange(event: ChangeEvent<HTMLInputElement>) {
        const changedIdentifier = event.target.value || undefined
        if (changedIdentifier) {
            setIdentifier(changedIdentifier)
        }
    }

    async function onUpload() {
        setState("LOADING")
        if (file) {
            try {
                const uploadedFile = await fetchClient.fileApi.upload(file, identifier)
                setFormValue({
                    fileId: uploadedFile.id,
                    fileIdentifier: identifier,
                    fileMimeType: uploadedFile.mimeType,
                    imgixUrl: uploadedFile.imgixUrl
                })
                setState("SUCCESS")
            }
            catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        else {
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "NONE" && (
                <>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept={accept.map(mapMimeTypeToAcceptString).join(",")}
                    />
                    <div className={styles.identifier}>
                        <input
                            type="text"
                            placeholder="Identifier"
                            onChange={handleIdentifierChange}
                            onKeyDown={preventSubmissionOnEnter}
                        />
                    </div>
                    <div className={styles.uploadButton}>
                        <Button
                            title="Upload"
                            style={ButtonStyle.PRIMARY}
                            type="button"
                            onClick={onUpload}
                        />
                    </div>
                </>
            )}
            {state === "LOADING" && (
                <div className={styles.loading}>
                    <LoadingBar/>
                    <div>Uploading...</div>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to upload."
                />
            )}
            {state === "SUCCESS" && (
                <>
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Successfully uploaded file."
                    />
                    <Button
                        title="Close"
                        style={ButtonStyle.PRIMARY}
                        type="button"
                        onClick={close}
                    />
                </>
            )}
        </>
    )
}

export default FileUpload

function mapMimeTypeToAcceptString(mimeType: MimeTypeOpenApi) {
    switch (mimeType) {
        case "JPG": return "image/jpeg"
        case "PDF": return "application/pdf"
        case "PNG": return "image/png"
        case "SVG": return "image/svg+xml"
    }
}