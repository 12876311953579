import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {FileUploadSelectFormValueType} from "../upload-select.type"
import {getStringRepresentation} from "../upload-select.util"
import styles from "./current-file.module.sass"

type FileUploadCurrentFilePreviewProps = {
    currentFile?: FileUploadSelectFormValueType
}

const FileUploadCurrentFilePreview: FunctionComponent<FileUploadCurrentFilePreviewProps> =({ currentFile }) => {
    if (!currentFile || !currentFile.imgixUrl) return <></>
    if (!currentFile.imgixUrl) {
        return (
            <div className={styles.currentFile}>
                <div className={styles.text}>
                    {getStringRepresentation(currentFile)}
                </div>
            </div>
        )
    }
    return (
        <div className={styles.currentFile}>
            <div className={styles.image}>
                <Imgix
                    src={currentFile.fileMimeType === "PDF" ? `${currentFile.imgixUrl}?page=1` : currentFile.imgixUrl}
                    height={40}
                    htmlAttributes={{ alt: currentFile.fileIdentifier || currentFile.fileId }}
                />
            </div>
            <div>
                <div className={styles.text}>
                    {getStringRepresentation(currentFile)}
                </div>
            </div>
        </div>
    )
}

export default FileUploadCurrentFilePreview