import {
    CurrencyOpenApi,
    DealExitTypeOpenApi,
    DealIndustryOpenApi,
    DealOutcomeOpenApi,
    DealPaymentInstructionsTriggerOpenApi,
    DealPaymentProcessingTypeOpenApi,
    DealPubliclyAvailableDataPointOpenApi,
    DealStageOpenApi,
    DealWaitingStateOpenApi
} from "../../generated"
import {DealGroupType} from "../deal-group/deal-group.type"
import {DealCategoryType} from "../deal-category/deal-category.type"
import {DealGeographyType} from "../deal-geography/deal-geography.type"
import {DealTileType} from "../deal-tile/deal-tile.type"
import {DealFeeStructureType} from "./fee/deal-fee-structure.type"
import {DealInvestorGroupType} from "./investorgroup/deal-investor-group.type"
import {SinglePurposeVehicleType} from "../single-purpose-vehicle/single-purpose-vehicle.type"
import {CompanyEmbeddableType} from "../company/company.type"
import {ImageType} from "../../tech/image/image.type"
import {
    DealManualReconciliationBankAccountType
} from "./reconciliationaccount/deal-manual-reconciliation-bank-account.type"
import {DealDocumentType} from "./document/document.type"
import {ShareClassType} from "../share-class/share-class.type"

export class DealType {
    public id: string | undefined
    public created: Date | undefined
    public modified: Date | undefined
    public sequentialId: number | undefined
    public name: DealNameType
    public description: DealDescriptionType
    public company: CompanyEmbeddableType | undefined
    public companyId: string | undefined
    public categories: DealCategoryType[]
    public geography: DealGeographyType | undefined
    public logo: ImageType | undefined
    public image: ImageType | undefined
    public stage: DealStageOpenApi
    public outcome: DealOutcomeOpenApi
    public waitingState: DealWaitingStateOpenApi | undefined
    public industry: DealIndustryOpenApi
    public minTarget: number
    public maxTarget: number
    public minTicket: number
    public finalDeadline: Date
    public currency: CurrencyOpenApi
    public investedAmount: number
    public exclusive: boolean
    public dealGroups: DealGroupType[]
    public investorGroups: DealInvestorGroupType[]
    public documentTemplateIds: number[]
    public investors: string | string[]
    public termsTags: string | string[]
    public tiles: DealTileType[]
    public slideDeck: DealSlideDeckType | undefined
    public videoYoutubeCode: string | undefined
    public documents: DealDocumentType[]
    public event: DealEventType | undefined
    public feeStructure: DealFeeStructureType
    public shareClassForNameInDealContract: ShareClassType
    public manualReconciliationBankAccounts: DealManualReconciliationBankAccountType[]
    public singlePurposeVehicle: SinglePurposeVehicleType
    public paymentProcessingType: DealPaymentProcessingTypeOpenApi
    public paymentInstructionsTrigger: DealPaymentInstructionsTriggerOpenApi
    public paymentInstructionsManuallyTriggered: Date | undefined
    public exited: Date | undefined
    public exitType: DealExitTypeOpenApi | undefined
    public published: boolean
    public imported: boolean
    public publiclyAvailable: boolean
    public publiclyAvailableDataPoints: DealPubliclyAvailableDataPointOpenApi[] | undefined
    public showCompletelyWithoutOnboarding: boolean

    constructor({
        id,
        created,
        modified,
        sequentialId,
        name,
        description,
        company,
        companyId,
        categories,
        geography,
        logo,
        image,
        stage,
        outcome,
        waitingState,
        industry,
        minTarget,
        maxTarget,
        minTicket,
        finalDeadline,
        currency,
        investedAmount,
        exclusive,
        dealGroups,
        investorGroups,
        documentTemplateIds,
        investors,
        termsTags,
        tiles,
        slideDeck,
        videoYoutubeCode,
        documents,
        event,
        feeStructure,
        shareClassForNameInDealContract,
        manualReconciliationBankAccounts,
        singlePurposeVehicle,
        paymentProcessingType,
        paymentInstructionsTrigger,
        paymentInstructionsManuallyTriggered,
        exited,
        exitType,
        published,
        imported,
        publiclyAvailable,
        publiclyAvailableDataPoints,
        showCompletelyWithoutOnboarding
    } : {
        id?: string,
        created: Date | undefined,
        modified: Date | undefined,
        sequentialId: number | undefined,
        name: DealNameType
        description: DealDescriptionType
        company: CompanyEmbeddableType | undefined
        companyId: string | undefined
        categories: DealCategoryType[]
        geography: DealGeographyType | undefined
        logo: ImageType | undefined
        image: ImageType | undefined
        stage: DealStageOpenApi
        outcome: DealOutcomeOpenApi
        waitingState: DealWaitingStateOpenApi | undefined
        industry: DealIndustryOpenApi
        minTarget: number
        maxTarget: number
        minTicket: number
        finalDeadline: Date
        currency: CurrencyOpenApi
        investedAmount: number
        exclusive: boolean
        dealGroups: DealGroupType[]
        investorGroups: DealInvestorGroupType[]
        documentTemplateIds: number[]
        investors: string | string[]
        termsTags: string | string[]
        tiles: DealTileType[]
        slideDeck: DealSlideDeckType | undefined
        videoYoutubeCode: string | undefined
        documents: DealDocumentType[]
        event: DealEventType | undefined
        feeStructure: DealFeeStructureType
        shareClassForNameInDealContract: ShareClassType
        manualReconciliationBankAccounts: DealManualReconciliationBankAccountType[]
        singlePurposeVehicle: SinglePurposeVehicleType
        paymentProcessingType: DealPaymentProcessingTypeOpenApi
        paymentInstructionsTrigger: DealPaymentInstructionsTriggerOpenApi
        paymentInstructionsManuallyTriggered: Date | undefined
        exited: Date | undefined
        exitType: DealExitTypeOpenApi | undefined
        published: boolean
        imported: boolean
        publiclyAvailable: boolean
        publiclyAvailableDataPoints: DealPubliclyAvailableDataPointOpenApi[] | undefined
        showCompletelyWithoutOnboarding: boolean
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.sequentialId = sequentialId
        this.name = name
        this.description = description
        this.company = company
        this.companyId = companyId
        this.categories = categories
        this.geography = geography
        this.logo = logo
        this.image = image
        this.stage = stage
        this.outcome = outcome
        this.waitingState = waitingState
        this.industry = industry
        this.minTarget = minTarget
        this.maxTarget = maxTarget
        this.minTicket = minTicket
        this.finalDeadline = finalDeadline
        this.currency = currency
        this.investedAmount = investedAmount
        this.exclusive = exclusive
        this.dealGroups = dealGroups
        this.investorGroups = investorGroups
        this.documentTemplateIds = documentTemplateIds
        this.investors = investors
        this.termsTags = termsTags
        this.tiles = tiles
        this.slideDeck = slideDeck
        this.videoYoutubeCode = videoYoutubeCode
        this.documents = documents
        this.event = event
        this.feeStructure = feeStructure
        this.shareClassForNameInDealContract = shareClassForNameInDealContract
        this.manualReconciliationBankAccounts = manualReconciliationBankAccounts
        this.singlePurposeVehicle = singlePurposeVehicle
        this.paymentProcessingType = paymentProcessingType
        this.paymentInstructionsTrigger = paymentInstructionsTrigger
        this.paymentInstructionsManuallyTriggered = paymentInstructionsManuallyTriggered
        this.exited = exited
        this.exitType = exitType
        this.published = published
        this.imported = imported
        this.publiclyAvailable = publiclyAvailable
        this.publiclyAvailableDataPoints = publiclyAvailableDataPoints
        this.showCompletelyWithoutOnboarding = showCompletelyWithoutOnboarding
    }

    public get minTargetFormatted(): string {
        return this.minTarget.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
    }

    public get maxTargetFormatted(): string | undefined {
        return this.maxTarget?.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
    }

    public get minTicketFormatted(): string {
        return this.minTicket.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
    }

    public get investedAmountFormatted(): string {
        return this.investedAmount.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
    }
}

export type DealNameType = {
    completeOnboarding: string
    incompleteOnboarding: string
}

export type DealDescriptionType = {
    completeOnboarding: string
    incompleteOnboarding: string
}

export type DealEventType = {
    title: string
    start: Date
    end?: Date
    location?: string
    url?: string
}

export type DealSlideDeckType = {
    fileId: string
    fileIdentifier: string | undefined
    url: string | undefined
}

export const mapDealIndustryToString = (industry: DealIndustryOpenApi): string => {
    switch (industry) {
        case "ADVERTISING": return "Advertising"
        case "AGRICULTURAL_TECH": return "Agritech"
        case "AI": return "AI"
        case "CLIMATE_TECH": return "Climate Tech"
        case "COMMERCE": return "Commerce"
        case "CONSUMER": return "Consumer"
        case "DEEP_TECH": return "Deep Tech"
        case "EDUCATION": return "Education"
        case "ENTERTAINMENT": return "Entertainment"
        case "FINTECH": return "Fintech"
        case "FOOD_AND_RESTAURANT_TECH": return "Food & Restaurant Tech"
        case "FUND": return "Fund"
        case "HEALTH": return "Health"
        case "HOSPITALITY_AND_PROP_TECH": return "Hospitality & Prop Tech"
        case "MOBILITY_AND_LOGISTICS": return "Mobility & Logistics"
        case "OTHERS": return "Others"
        case "SAAS": return "SaaS"
    }
}

export const mapDealStageToString = (stage: DealStageOpenApi): string => {
    switch (stage) {
        case "NONE": return "NONE"
        case "EARLY_STAGE": return "Early Stage"
        case "GROWTH_STAGE": return "Growth Stage"
        case "LATE_STAGE": return "Late Stage"
        case "FUND": return "Fund"
        case "SEED": return "Seed"
        case "SERIES_A": return "Series A"
        case "SERIES_B": return "Series B"
        case "SERIES_C": return "Series C"
        case "PRE_IPO": return "Pre-IPO"
    }
}

export const mapDealOutcomeToString = (outcome: DealOutcomeOpenApi): string => {
    switch (outcome) {
        case "NONE": return "None"
        case "FINALIZED": return "Finalized"
        case "UNSUCCESSFUL": return "Unsuccessful"
    }
}

export const mapDealWaitingStateToString = (waitingState: DealWaitingStateOpenApi): string => {
    switch (waitingState) {
        case "WAITING_FOR_MINIMUM_AMOUNT": return "Waiting for minimum amount"
        case "WAITING_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER": return "Waiting for manual payment instructions trigger"
        case "WAITING_FOR_PAYMENTS": return "Waiting for payment"
    }
}

export function mapDealExitTypeToString(exitType: DealExitTypeOpenApi | undefined): string | undefined {
    if (exitType === undefined) return undefined
    switch (exitType) {
        case "DEAD": return "Dead"
        case "EXIT": return "Exit"
    }
}