import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {SYNDICATE_MEMBERS_INVITATIONS} from "../../../paths"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {InvestorWithDetailsType} from "../../investor/investor.type"
import {SyndicateType} from "../syndicate.type"

const SyndicateMemberRemove = () => {
    let { syndicateId, investorId } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [member, setMember] = useState<InvestorWithDetailsType>()
    const [syndicate, setSyndicate] = useState<SyndicateType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedMember,
                    fetchedSyndicate
                ] = await Promise.all([
                    fetchClient.investorApi.get(investorId!),
                    fetchClient.syndicateApi.get(syndicateId!)
                ])
                setMember(fetchedMember)
                setSyndicate(fetchedSyndicate)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, syndicateId, investorId])

    const remove = async () => {
        setState("LOADING")
        try {
            await fetchClient.syndicateApi.removeMember(syndicateId!, investorId!)
            navigate(SYNDICATE_MEMBERS_INVITATIONS(syndicateId!))
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    const person = member?.investor.person
    const investorName = person?.firstName && person.lastName && person.contact.email
        ? `${person.firstName} ${person.lastName} (${person.contact.email})`
        : member?.investor.id

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="An error occurred. Please try again or contact the tech team."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && syndicate && member && (
                <Section>
                    <div className="mb-5">
                        Do you really want to remove investor <strong>{investorName}</strong> from syndicate <strong>{syndicate.name}</strong>?
                    </div>
                    <Button
                        title="Remove from syndicate"
                        style={ButtonStyle.DELETE}
                        onClick={remove}
                        type="button"
                    />
                </Section>
            )}
        </>
    )
}

export default SyndicateMemberRemove